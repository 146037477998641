import React, { useState, useEffect, useContext, createContext, PropsWithChildren } from 'react';
import Router from 'next/router';
import cookie from 'js-cookie';
import get from 'lodash/get';
import LogRocket from 'logrocket';

import { useToast } from '@chakra-ui/react';
import firebase from './firebase';
import Link from 'next/link';

type UserProfile = {
  firstName: string;
  lastName: string;
  address: {
    zipCode: string;
    city: string;
    street: string;
    houseNumber: string;
    streetAddition: string;
  };
  gender: string;
  companyName: { short: string; main: string; addition: string };
  telephone: string;
  fax: string;
  url: string;
  email: string;
};

export type User = {
  id: string;
  email: string;
  emailVerified: boolean;
  allowedRound?: number;
  isAdmin?: boolean;
  isSudo?: boolean;
  lastSignIn?: string;
  name: string;
  token: string;
  uid: string;
  profile?: UserProfile;
  azv?: Record<string, any>;
  lager?: Record<string, any>;
  organik?: Record<string, any>;
};

type Round = {
  isFinished: boolean;
  round: number;
  changedAt: string;
};

type ContextReturn = ReturnType<typeof useProvideAuth>;

const authContext = createContext<ContextReturn | undefined>(undefined);

export const useAuth = () => {
  return useContext(authContext) as ContextReturn;
};

const formatUser = async (user: firebase.User) => {
  return {
    id: user.uid,
    uid: user.uid,
    email: user.email,
    name: user.displayName,
    token: await user.getIdToken(),
    emailVerified: user.emailVerified,
  } as User;
};

const today = new Date();

const useProvideAuth = () => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [round, setRound] = useState<number | null>(null);
  const [isRoundFinished, setIsRoundFinished] = useState(false);
  const [roundFinishedAt, setRoundFinishedAt] = useState<string | undefined>();

  const toast = useToast();

  const fetchUserData = async (uid: string) => {
    setLoading(true);
    try {
      const resp = await firebase.firestore().collection('users').doc(uid).get();
      const userData = resp.data() as User;

      setUser((current) => ({
        ...current,
        ...userData,
      }));
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCurrentRound = async () => {
    try {
      const resp = await firebase.firestore().collection('runde').doc('currentRound').get();

      const { round: fetchedRound, isFinished, changedAt } = resp.data() as Round;

      setRound(fetchedRound);
      setIsRoundFinished(isFinished);
      setRoundFinishedAt(changedAt);
    } catch (error) {
      console.log('error', error);
    }
  };

  const finishCurrentRound = async () => {
    try {
      await firebase
        .firestore()
        .collection('runde')
        .doc('currentRound')
        .set({ isFinished: true, changedAt: today.toISOString() }, { merge: true });
      fetchCurrentRound();
    } catch (error) {
      console.log('error', error);
    }
  };

  const setCurrentRound = async (roundNumber: number) => {
    try {
      await firebase.firestore().collection('runde').doc('currentRound').set({ round: roundNumber, isFinished: false });

      await fetchCurrentRound();
    } catch (error) {
      console.log('setCurrentRoundError', error);
    }
  };

  const allowUserForRound = async (uid: string, toBeAllowedRound: number) => {
    setLoading(true);
    try {
      await firebase.firestore().collection('users').doc(uid).set({ allowedRound: toBeAllowedRound }, { merge: true });

      fetchUserData(uid);
    } catch (error) {
      console.log('allowUserForRound', error);
    } finally {
      setLoading(false);
    }
  };

  const handleUser = async (userData?: firebase.User) => {
    setLoading(true);
    if (userData) {
      const userObject = await formatUser(userData);
      const token = await userData.getIdToken();

      cookie.set('accessToken', token, {
        expires: 7,
      });

      await fetchUserData(userObject.uid);
      setLoading(false);

      if (userObject.email) {
        LogRocket.identify(userObject?.uid, {
          email: userObject.email,
        });
      }

      return userObject;
    }

    setUser(null);
    cookie.remove('accessToken');
    setLoading(false);

    return;
  };

  const signOut = () => {
    setLoading(true);
    return firebase
      .auth()
      .signOut()
      .then(() => {
        handleUser();
        setLoading(false);
        Router.push('/login');
      });
  };

  const createUpdateUserInCollection = async (userData: User) => {
    setLoading(true);
    try {
      await firebase
        .firestore()
        .collection('users')
        .doc(userData.uid)
        .set(
          {
            ...userData,
            lastSignIn: today.toISOString(),
          },
          { merge: true }
        );
    } catch (error) {
      console.log('errorFirestore', error);
    } finally {
      setLoading(false);
    }
  };

  const updateUserProfile = async (profileData: UserProfile) => {
    setLoading(true);
    try {
      if (!user) {
        throw Error('No user present to update!');
      }

      await firebase.firestore().collection('users').doc(user.uid).set(
        {
          profile: profileData,
        },
        { merge: true }
      );

      fetchUserData(user.uid);
    } catch (error) {
      console.log('userProfile', error);
    } finally {
      setLoading(false);
    }
  };

  const resetPassword = async (emailAddress: string) => {
    try {
      await firebase.auth().sendPasswordResetEmail(emailAddress);

      toast({
        position: 'top-right',
        title: 'Passwort zurückgesetzt',
        description: 'Wir haben Ihnen einen Link zum zurücksetzen Ihres Passwortes geschickt',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.log(error);

      if (error.code === 'auth/invalid-email') {
        return toast({
          position: 'top-right',
          title: 'Fehler',
          description: 'Bitte geben Sie eine valide E-mailadresse an',
          status: 'error',
          duration: 5000,
        });
      }

      return toast({
        position: 'top-right',
        title: 'Fehler',
        description: 'Ihr Passwort konnte nicht zurueckgesetzt werden, bitte versuchen Sie es erneut.',
        status: 'error',
        duration: 5000,
      });
    }
  };

  const sendVerfificationEmail = async () => {
    const { currentUser } = firebase.auth();

    if (!currentUser) {
      console.error('No current user present to send email to');
      return;
    }

    await currentUser.sendEmailVerification();
  };

  const signInWithEmail = (email: string, password: string) => {
    setLoading(true);
    return firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(async (response) => {
        if (!response.user) {
          console.error('Auth user not found!');
          return;
        }

        handleUser(response.user);

        await createUpdateUserInCollection(await formatUser(response.user));

        const fromPath = get(Router, 'router.query.from');

        Router.push(fromPath || '/dashboard');
      })
      .catch((_error) => {
        const ToastDescritpion = () => (
          <div>
            Ihre eingegebenen Daten sind nicht korrekt, bitte versuchen Sie es erneut. Noch keinen Account?{' '}
            <Link href="/register" style={{ fontWeight: 'bold' }}>
              Klicken Sie hier
            </Link>
          </div>
        );

        toast({
          position: 'top-right',
          title: 'Fehler',
          description: <ToastDescritpion />,

          status: 'error',
          isClosable: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const signUpWithEmail = async (email: string, password: string) => {
    setLoading(true);

    await firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(async (response) => {
        if (!response.user) {
          console.error('createUserWithEmailAndPassword no user in response!');
          return;
        }

        await handleUser(response.user);

        await createUpdateUserInCollection({ allowedRound: round || 1, ...(await formatUser(response.user)) });

        await sendVerfificationEmail();

        await fetch('/api/sendRegisterMail', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email,
          }),
        });

        toast({
          position: 'top-right',
          title: 'Account erstellt',
          description: 'Ihr account wurde erfolgreich erstellt. Wir haben Ihnen eine Bestätigungsmail zugesandt.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });

        Router.push('/login');
      })
      .catch((error) => {
        if (error.code === 'auth/email-already-in-use') {
          toast({
            position: 'top-right',
            title: 'Fehler',
            description: 'Es gibt bereits einen Nuzter mit dieser Email.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        } else {
          toast({
            position: 'top-right',
            title: 'Fehler',
            description: `Es ist ein Fehler bei Ihrer Registrierung aufgetreten, bitte wenden Sie sich an unseren Support.
            Fehlercode: ${error.code}
            `,
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
        setLoading(false);
      });
  };

  const fetchAllUsers = async (round?: number) => {
    setLoading(true);
    try {
      const snapshot = round
        ? await firebase.firestore().collection('users').where('allowedRound', '==', round).get()
        : await firebase.firestore().collection('users').get();

      return snapshot.docs
        .map((userSnapShot) => {
          const currentID = userSnapShot.id;
          const userObj = { ...userSnapShot.data(), id: currentID } as User;

          return userObj;
        })
        .sort((prev, curr) => {
          if (prev.email < curr.email) {
            return -1;
          }
          if (prev.email > curr.email) {
            return 1;
          }
          return 0;
        });
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  };

  const updateUserCollection = async (uid: string) => {
    setLoading(true);
    try {
      await firebase.firestore().collection('users').doc(uid).set(
        {
          emailVerified: true,
        },
        { merge: true }
      );
    } catch (error) {
      console.log('userProfile', error);
    } finally {
      setLoading(false);
    }
  };

  const handleOnAuthChange = async (userData: firebase.User) => {
    if (userData) {
      const userObject = await formatUser(userData);

      if (userObject?.emailVerified) {
        updateUserCollection(userObject.uid);
      }
    }

    try {
      await fetchCurrentRound();
      await handleUser(userData);
    } catch (error) {
      console.log('handleOnAuthChange', error);
    }
  };

  const makeUserAdmin = async (uid: string, makeAdmin: boolean) => {
    try {
      await firebase.firestore().collection('users').doc(uid).set(
        {
          isAdmin: makeAdmin,
        },
        { merge: true }
      );
    } catch (error) {
      console.log('makeUserAdmin', error);
    }
  };

  const backFillallowedRound = async (uid: string) => {
    setLoading(true);
    try {
      await firebase
        .firestore()
        .collection('users')
        .doc(uid)
        .set(
          {
            allowedRound: round || 1,
          },
          { merge: true }
        );
    } catch (error) {
      console.log('userProfile', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const authListener = firebase.auth().onAuthStateChanged(handleOnAuthChange);

    return authListener;
  }, []);

  useEffect(() => {
    if (user && !user?.allowedRound) {
      backFillallowedRound(user?.uid);
    }

    if (round && user?.allowedRound && user?.allowedRound && user?.allowedRound < round) {
      if (!user?.isAdmin) {
        toast.closeAll();

        toast({
          position: 'top-right',
          title: 'Zulassung',
          description:
            'Wir werten die eingegangenen Angebote aus. Deshalb haben Sie keinen Zugang zur Ausschreibungsplattform. Sobald unsere Auswertung abgeschlossen ist, teilen wir Ihnen das Ergebnis für Ihre Angebote mit und öffnen das Portal wieder für diejenigen Bieter, mit denen wir abschließende Vergabegespräche führen. Bis dahin bitten wir Sie um etwas Geduld.',
          status: 'error',
          duration: 20000,
          isClosable: true,
        });

        signOut();
      }
    }
  }, [user, round]);

  return {
    user,
    loading,
    signInWithEmail,
    signUpWithEmail,
    resetPassword,
    updateUserProfile,
    signOut,
    sendVerfificationEmail,
    round,
    fetchAllUsers,
    setCurrentRound,
    finishCurrentRound,
    allowUserForRound,
    isRoundFinished: isRoundFinished && !user?.isAdmin,
    roundFinishedAt,
    makeUserAdmin,
  };
};

export function AuthenticationContextProvider({ children }: PropsWithChildren) {
  const auth = useProvideAuth();

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}
