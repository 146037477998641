export const importantInfos = [
  { title: 'Informationen zur Ausschreibung', type: 'Informationen_zur_Ausschreibung' },
  { title: 'Details zu LOS AzV', type: 'Details_zu_Los_AzV' },
  { title: 'Details zu LOS Organik', type: 'Details_zu_LOS_Organik' },
  {
    title: 'Vertragsmuster Los AzV & Organik',
    type: 'REWE_Filialen_Mustervertrag',
  },
  { title: 'Abrechnung', type: 'Abrechnung' },
  { title: 'Zeitplan der Ausschreibung', type: 'Zeitplan_der_Ausschreibung' },
  { title: 'Datenschutzerklärung', type: 'Datenschutzerklärung' },
  { title: 'Aktuelle Informationen', type: 'Aktuelle_Informationen' },
  { title: 'REWE Ausschreibungsdaten', type: 'REWE Ausschreibungsdaten zum Download', isDownloadable: true },
];

export const clusterName = [
  { referenceId: 1, label: 'Schleswig Holstein, Hamburg, Mecklenburg-Vorpommern, Bremen, Niedersachsen' },
  { referenceId: 2, label: 'Berlin, Brandenburg, Sachsen-Anhalt, Sachsen, Thüringen' },
  { referenceId: 3, label: 'Nordrhein-Westfalen, Hessen' },
  { referenceId: 4, label: 'Rheinland-Pfalz, Baden-Württemberg, Saarland' },
  { referenceId: 5, label: 'Bayern' },
];

export const bundeslandNames = [
  { reference: 'BW', label: 'Baden-Württemberg' },
  { reference: 'BY', label: 'Bayern' },
  { reference: 'BE', label: 'Berlin' },
  { reference: 'BB', label: 'Brandenburg' },
  { reference: 'HB', label: 'Bremen' },
  { reference: 'HH', label: 'Hamburg' },
  { reference: 'HE', label: 'Hessen' },
  { reference: 'MV', label: 'Mecklenburg-Vorpommern' },
  { reference: 'NI', label: 'Niedersachsen' },
  { reference: 'NW', label: 'Nordrhein-Westfalen' },
  { reference: 'RP', label: 'Rheinland-Pfalz' },
  { reference: 'SL', label: 'Saarland' },
  { reference: 'SN', label: 'Sachsen' },
  { reference: 'ST', label: 'Sachsen-Anhalt' },
  { reference: 'SH', label: 'Schleswig-Holstein' },
  { reference: 'TH', label: 'Thüringen' },
];
