// you can import these packages anywhere
const LogRocket = require('logrocket');
const setupLogRocketReact = require('logrocket-react');

export const logRocketInit = () => {
  // only initialize when in the browser
  if (process.env.NODE_ENV === 'production' && typeof window !== 'undefined') {
    LogRocket.init('ex4rel/rewe-2021');
    // plugins should also only be initialized when in the browser
    setupLogRocketReact(LogRocket);
  }
};
