import React from 'react';
import { ChakraProvider, CSSReset, theme } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { Global } from '@emotion/react';
import Head from 'next/head';

import { AuthenticationContextProvider } from '../services/AuthenticationContext';
import { OfferProvider } from '../services/db';
import Header from '../components/Header';
import customTheme from '../theme';
import Footer from '../components/Footer';

import '../components/Loading/RowsLoading/styles.css';
import { logRocketInit } from '../config/logRocketInit';

logRocketInit();

const WrappedApp = ({ Component, pageProps }: { Component: React.FunctionComponent; pageProps: Record<string, any> }) => {
  const { pathname } = useRouter();

  return (
    <ChakraProvider theme={{ ...theme, colors: { ...theme.colors, ...customTheme.colors }, fonts: customTheme.fonts }}>
      <AuthenticationContextProvider>
        <OfferProvider>
          <Global
            styles={{
              body: {
                color: theme.colors.gray[600],
                // overflowX: 'hidden',
                maxWidth: '100vw',
              },
            }}
          />
          <CSSReset />
          <Head>
            <title>{`Ausschreibung ${new Date().getFullYear()}`}</title>
            <meta name="viewport" content="initial-scale=1.0, width=device-width" />
          </Head>
          <Header noMenu={!pathname.includes('/dashboard')} />
          <Component {...pageProps} />
          <Footer />
        </OfferProvider>
      </AuthenticationContextProvider>
    </ChakraProvider>
  );
};

export default WrappedApp;
