import { createContext, useContext, useState, useEffect, PropsWithChildren } from 'react';
import Router from 'next/router';
import { get, map } from 'lodash';
import { useToast } from '@chakra-ui/react';

import firebase from './firebase';
import { useAuth } from './AuthenticationContext';
import { LosTypes } from '../types';
import { AZVOfferNamespace, LagerOfferNamespace, Offers, OrganikOfferNamespace } from '../types/gaia.types';

type ContextReturn = ReturnType<typeof useDatabaseContextValue>;

const offerContext = createContext<ContextReturn | undefined>(undefined);

export const useOffer = () => {
  return useContext(offerContext) as ContextReturn;
};

const useDatabaseContextValue = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [azv, setAzv] = useState(null);
  const [organik, setOrganik] = useState(null);
  const [lager, _setLager] = useState(null);

  const auth = useAuth();
  const toast = useToast();
  const today = new Date();

  const getExistingOffers = async (collection: LosTypes, uid: string) => {
    setIsLoading(true);
    try {
      const userId = uid || auth?.user?.uid;

      if (!userId) return null;

      const resp = await firebase.firestore().collection(collection).doc(userId).get();

      if (resp) {
        return resp.data();
      }

      return null;
    } catch (error) {
      console.log('getExistingOffers', error);
      setHasError(true);
      throw new Error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchData = async (setData: boolean) => {
    setIsLoading(true);
    const { round, user } = auth;
    const currentRound = round || 1;

    try {
      if (user) {
        const azv_raw = await getExistingOffers(LosTypes.AZV, user.uid);
        const organik_raw = await getExistingOffers(LosTypes.ORGANIK, user.uid);
        // const lager_raw = await getExistingOffers(LosTypes.LAGER, user.uid);

        const azvData = get(azv_raw, `round.${currentRound}`);
        const organikData = get(organik_raw, `round.${currentRound}`);
        // const lagerData = get(lager_raw, `round.${currentRound}`);

        if (setData) {
          setAzv(azvData);
          setOrganik(organikData);
          // setLager(lagerData);
        }

        return {
          azvData,
          organikData,
          // lagerData,
        };
      }
    } catch (error) {
      console.log('error', error);
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAllUserOffers = async () => {
    setIsLoading(true);
    const { user } = auth;

    try {
      if (user) {
        const azv_raw = await getExistingOffers(LosTypes.AZV, user.uid);
        const organik_raw = await getExistingOffers(LosTypes.ORGANIK, user.uid);
        const lager_raw = await getExistingOffers(LosTypes.LAGER, user.uid);

        return {
          azv_raw,
          organik_raw,
          lager_raw,
        };
      }
    } catch (error) {
      console.log('fetchAllUserOffers', error);
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const createUpdateUserOffer = async ({
    collection,
    offers,
    inputValues,
    activeRows,
    uid,
    selectedRound,
  }: {
    collection: string;
    offers: unknown[];
    inputValues: Record<string, Record<string, unknown>>;
    activeRows: unknown[];
    uid: string;
    selectedRound: number;
  }) => {
    setIsLoading(true);

    try {
      const currentRound = selectedRound || auth.round || 1;
      if (auth.isRoundFinished) throw Error('Abgabe runde ist beendet');

      map(inputValues, (value) => {
        map(value, (rowInputValue, rowInputkey) => {
          if (rowInputValue === undefined || rowInputValue === null) {
            delete value[rowInputkey];
          }
        });
      });

      const mappedInputValues = Object.keys(inputValues).map((key) => {
        return { row: key, ...inputValues[key] };
      });

      const currentOffer = {
        round: {
          [currentRound]: {
            offers,
            inputValues: mappedInputValues,
            activeRows,
            createdAt: today.toISOString(),
          },
        },
      };

      await firebase
        .firestore()
        .collection(collection)
        .doc(uid)
        .set({ user: uid, ...currentOffer }, { merge: true });

      await firebase
        .firestore()
        .collection('users')
        .doc(uid)
        .set({ [collection]: { [currentRound]: { offered: true } } }, { merge: true });

      await fetchData(true);

      Router.push('/dashboard/meineAngebote');
    } catch (error) {
      console.log('createUpdateUserOffer', error);
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const submitOffer = async (collection: string, docId: string) => {
    setIsLoading(true);

    try {
      const currentRound = auth.round || 1;

      const submittedOffer = {
        round: {
          [currentRound]: { submitted: { submittedAt: today.toISOString(), isSubmitted: true } },
        },
      };

      await firebase.firestore().collection(collection).doc(docId).set(submittedOffer, { merge: true });

      await firebase
        .firestore()
        .collection('users')
        .doc(docId)
        .set({ [collection]: { [currentRound]: { submitted: true, submittedAt: today.toISOString() } } }, { merge: true });

      fetchData(true);
    } catch (error) {
      console.log('error', error);
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteOffer = async (collection: string, uid: string) => {
    const currentRound = auth.round || 1;

    try {
      await firebase
        .firestore()
        .collection(collection)
        .doc(uid)
        .set(
          {
            round: {
              [currentRound]: null,
            },
          },
          { merge: true }
        );

      await firebase
        .firestore()
        .collection('users')
        .doc(uid)
        .set({ [collection]: { [currentRound]: { offered: false } } }, { merge: true });

      toast({
        position: 'top-right',
        title: 'Gelöscht',
        description: 'Ihr Angebot wurde erfolgreich gelöscht.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      fetchData(true);
    } catch (error) {
      setHasError(true);
      console.log('deleting error', error);
    }
  };

  const fetchCompleteOffersCollection = async () => {
    setIsLoading(true);
    const offersOrganik: OrganikOfferNamespace.OfferRoot[] = [];
    const offersAzv: AZVOfferNamespace.OfferRoot[] = [];
    const offersLager: LagerOfferNamespace.OfferRoot[] = [];

    const snapshotOrganik = await firebase.firestore().collection('organik').get();
    const snapshotAzv = await firebase.firestore().collection('azv').get();
    const snapshotLager = await firebase.firestore().collection('lager').get();

    snapshotOrganik.docs.map((offerSnapshot) => {
      const currentID = offerSnapshot.id;
      const offerObject = { ...offerSnapshot.data(), id: currentID } as OrganikOfferNamespace.OfferRoot;

      return offersOrganik.push(offerObject);
    });

    snapshotAzv.docs.map((offerSnapshot) => {
      const currentID = offerSnapshot.id;
      const offerObject = { ...offerSnapshot.data(), id: currentID } as AZVOfferNamespace.OfferRoot;

      return offersAzv.push(offerObject);
    });

    snapshotLager.docs.map((offerSnapshot) => {
      const currentID = offerSnapshot.id;
      const offerObject = { ...offerSnapshot.data(), id: currentID } as LagerOfferNamespace.OfferRoot;

      return offersLager.push(offerObject);
    });

    setIsLoading(false);

    return { offersOrganik, offersAzv, offersLager } as unknown as Offers;
  };

  const getStorageUrl = async (fileName: string) => {
    try {
      const storage = await firebase.storage();

      return await storage.ref(fileName).getDownloadURL();
    } catch (error) {
      console.log('error', error);
      return '';
    }
  };

  const copyLastRoundOffer = async (type: LosTypes, uid: string, round = 1) => {
    try {
      setIsLoading(true);
      const allOffers = await fetchAllUserOffers();

      if (!allOffers) {
        console.error('No offers found!');
        return;
      }

      const { azv_raw, organik_raw, lager_raw } = allOffers;

      const lastRoundOffers = {
        azv: azv_raw?.round?.[round - 1],
        organik: organik_raw?.round?.[round - 1],
        lager: lager_raw?.round?.[round - 1],
      };

      await firebase
        .firestore()
        .collection(type)
        .doc(uid)
        .set(
          {
            round: {
              [round]: { ...lastRoundOffers[type], submitted: false },
            },
          },
          { merge: true }
        );

      await firebase
        .firestore()
        .collection('users')
        .doc(uid)
        .set({ [type]: { [round]: { offered: true } } }, { merge: true });

      fetchData(true);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (auth.user) fetchData(true);
  }, [auth.user, auth.round]);

  return {
    azv,
    organik,
    lager,
    getExistingOffers,
    createUpdateUserOffer,
    deleteOffer,
    submitOffer,
    isLoading,
    hasError,
    fetchCompleteOffersCollection,
    getStorageUrl,
    fetchAllUserOffers,
    copyLastRoundOffer,
  };
};

export const OfferProvider = ({ children }: PropsWithChildren<any>) => {
  const offer = useDatabaseContextValue();

  return <offerContext.Provider value={offer}>{children}</offerContext.Provider>;
};
