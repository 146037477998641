import React from 'react';
import { Flex, Link } from '@chakra-ui/react';
import { useRouter } from 'next/router';

const Footer = () => {
  const { pathname } = useRouter();
  const hasLightFooter =
    pathname.includes('/dashboard') ||
    pathname.includes('/contact') ||
    pathname.includes('/impressum') ||
    pathname.includes('/regions') ||
    pathname.includes('/login') ||
    pathname.includes('/register') ||
    pathname.includes('/forgot-password');

  return (
    <Flex width="100%" padding="16px" position="absolute" bottom="-56px" color={hasLightFooter ? 'gray600' : 'white'}>
      <Link href="contact" mr="16px">
        Kontakt
      </Link>
      <Link href="/impressum">Impressum</Link>
    </Flex>
  );
};

export default Footer;
