import React from 'react';
import Router from 'next/router';
import { Flex, Image, Box } from '@chakra-ui/react';

import Menu from '../HeaderMenu';

const Header = ({ noMenu }) => {
  return (
    <Flex
      as="nav"
      alignItems="center"
      justifyContent="space-between"
      width="100%"
      borderBottom="1px"
      borderColor="gray.200"
      boxShadow="0 1px 10px 6px rgba(12,13,14,0.15)"
    >
      <Box>
        <Image
          height="100px"
          src="/logo.png"
          alt="Rewe Logo"
          p="8px"
          rounded="15px"
          onClick={() => Router.push('/dashboard')}
          cursor="pointer"
        />
      </Box>
      {noMenu ? null : <Menu />}
    </Flex>
  );
};

export default Header;
