import React from 'react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { Flex, Box, useDisclosure } from '@chakra-ui/react';
import get from 'lodash/get';

import { useAuth } from '../../services/AuthenticationContext';
import UserSidebar from '../UserSidebar';

const MenuComponent = () => {
  const { onOpen, isOpen, onClose } = useDisclosure();

  const { user, signOut } = useAuth();

  const handleLogout = async () => {
    await onClose();
    signOut();
  };

  const firstName = get(user, 'profile.firstName', '');
  const lastName = get(user, 'profile.lastName', '');

  const gender = get(user, 'profile.gender', null);

  const companyName = get(user, 'profile.companyName.main');

  const isAdmin = get(user, 'isAdmin');

  return (
    <Flex mr="16px">
      <UserSidebar
        isOpen={isOpen}
        onClose={onClose}
        firstName={firstName}
        lastName={lastName}
        gender={gender}
        companyName={companyName}
        handleLogout={handleLogout}
        isAdmin={isAdmin}
      />
      <Box cursor="pointer" onClick={onOpen}>
        <HamburgerIcon boxSize={8} mr="1.5em" />
      </Box>
    </Flex>
  );
};

export default MenuComponent;
