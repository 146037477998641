import React, { useEffect, useMemo, useState } from 'react';

import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, Text, Flex } from '@chakra-ui/react';
import { useOffer } from '../../../services/db';
import { importantInfos } from '../../../config/constants';

const LosInfoModal = ({ onCloseClick, isOpen, infoType }) => {
  const [url, setUrl] = useState('');
  const { getStorageUrl } = useOffer();

  useEffect(() => {
    const fetchUrl = async () => {
      const filePath = await getStorageUrl(`Info/${infoType}.pdf`);
      setUrl(filePath || '');
    };

    if (infoType) {
      fetchUrl();
    }
  }, [infoType]);

  const title = useMemo(() => importantInfos.find(({ type }) => type === infoType)?.title || '', []);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onCloseClick} size="full" scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody height="100vh" padding={0}>
            {url ? (
              <iframe style={{ height: '100vh', width: '100%' }} src={url} title="pdf-content" />
            ) : (
              <Flex justifyContent="center" alignItems="center" height="100%">
                <Text>Informationen werden geladen...</Text>
              </Flex>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default LosInfoModal;
