// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from 'firebase/app';

// Add the Firebase products that you want to use
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

if (typeof window !== 'undefined' && !firebase.apps.length) {
  firebase.initializeApp({
    apiKey: 'AIzaSyAASZKcCitVLcpoInMp1UjjdXglhcMwsdQ',
    authDomain: 'rewe-c96db.firebaseapp.com',
    databaseURL: 'https://rewe-c96db.firebaseio.com',
    projectId: 'rewe-c96db',
    storageBucket: 'rewe-c96db.appspot.com',
    messagingSenderId: '537626408869',
    appId: '1:537626408869:web:5a5ad4fc433f5296531a4c',
    measurementId: 'G-K76W4PVRC6',
  });
  // Get a reference to the storage service, which is used to create references in your storage bucket
  firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
}

export default firebase;
