import { headerNames as azvHeaders, editableInputArray as azvInput } from '../config/azv/index';
import { headerNames as organikHeaders, editableInputArray as organikInput } from '../config/organik/index';

export enum LosTypes {
  AZV = 'azv',
  ORGANIK = 'organik',
  LAGER = 'lager',
}

export type HeaderNamesUnion = typeof azvHeaders | typeof organikHeaders;
export type EditableInputUnion = typeof azvInput | typeof organikInput;
