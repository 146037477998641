import React, { useState } from 'react';
import Router from 'next/router';
import {
  Box,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Flex,
  Text,
  Divider,
  useDisclosure,
} from '@chakra-ui/react';
import { importantInfos } from '../../config/constants';
import LosInfoModal from '../Modals/LosInfos';
import { useOffer } from '../../services/db';

const UserSidebar = ({ isOpen, onClose, firstName, lastName, gender, companyName, handleLogout, isAdmin }) => {
  const [infoType, setInfoType] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen: modalIsOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure();
  const { getStorageUrl } = useOffer();

  const setGender = () => {
    switch (gender) {
      case 'm':
        return 'Herr';
      case 'f':
        return 'Frau';
      default:
        return '';
    }
  };

  const handleRedirect = (href) => {
    Router.push(href);
    onClose();
  };

  const handleInfoClick = async ({ type, isDownloadable }) => {
    setIsLoading(type);
    if (isDownloadable) {
      try {
        const filePath = await getStorageUrl(`Info/${type}.xlsx`);

        const hiddenElement = document.createElement('a');
        hiddenElement.href = filePath;
        hiddenElement.download = `${type}.xlsx`;
        hiddenElement.click();
      } catch (error) {
        console.log('error');
      }
    } else {
      setInfoType(type);
      onModalOpen();
    }

    setIsLoading(false);
  };

  return (
    <>
      <LosInfoModal infoType={infoType} isOpen={modalIsOpen} onOpen={onModalOpen} onCloseClick={onModalClose} />
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="xs">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            {companyName}
            <>
              {firstName && lastName ? (
                <Text color="gray.400" fontSize="16px" fontWeight="normal">
                  {`${setGender()} ${firstName[0]}. ${lastName}`}
                </Text>
              ) : null}
            </>
          </DrawerHeader>
          {companyName ? <Divider /> : null}
          <DrawerBody>
            <Flex flexDir="column" height="100%">
              <Box>
                {importantInfos.map(({ title, type, isDownloadable }) => {
                  let colorScheme = {};

                  switch (type) {
                    case 'Aktuelle_Informationen':
                      colorScheme = { colorScheme: 'blue' };
                      break;
                    case 'REWE Ausschreibungsdaten zum Download':
                      colorScheme = { colorScheme: 'green' };
                      break;
                    default:
                      colorScheme = {};
                      break;
                  }

                  const buttonIsLoading = isLoading === type;

                  return (
                    <Button
                      key={title}
                      width="100%"
                      variant="ghost"
                      mb="16px"
                      onClick={() => handleInfoClick({ type, isDownloadable })}
                      isLoading={buttonIsLoading}
                      {...colorScheme}
                    >
                      {title}
                    </Button>
                  );
                })}
              </Box>
              <Divider mb="16px" />
              <Button padding="6px" colorScheme="red" variant="outline" onClick={() => handleRedirect('/dashboard/meineDaten')} mb="16px">
                Meine Daten
              </Button>
              <Button
                padding="6px"
                colorScheme="red"
                variant="outline"
                onClick={() => handleRedirect('/dashboard/meineAngebote')}
                mb="16px"
              >
                Meine Angebote
              </Button>
              {isAdmin ? (
                <Button padding="6px" colorScheme="red" onClick={() => handleRedirect('/dashboard/gaia')}>
                  Auswertungsschnittstelle
                </Button>
              ) : null}
            </Flex>
          </DrawerBody>

          <DrawerFooter>
            <Button onClick={handleLogout}>Abmelden</Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default UserSidebar;
