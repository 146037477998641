// example theme.js
export default {
  fonts: {
    heading: "'Roboto', sans-serif",
    body: "'Roboto', sans-serif",
    mono: "'Roboto', sans-serif",
  },
  colors: {
    reweRot: '#cd1316',
  },
};
